.delivery-address-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    padding-bottom: 15px;
}

.delivery-address-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 15px;
}

.edit__link {
    margin-right: 40px;
}

.edit__link > a {
    color: #146ca7;
    font-weight: 500;
    white-space: nowrap;
}