.html5-qr-code-plugin-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.html5-qr-code-plugin__slider {
    position: absolute;
    bottom: 10px;
    width: 300px;
}