.product-card-images {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    justify-content: flex-start
}

.product-card-images__image {
    width: 110px;
    height: 167px;
}