.upload-dropzone {
    width: 100%;
}

.upload-dropzone__dropzone {
    border: 2px dashed #CBCBCB;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    padding: 20px;
    outline: none;
    z-index: 50;
    position: relative;
}

.upload-dropzone__body {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-dropzone__text {
    margin-bottom: 10px;
    color: #868686;
    font-size: 18px;
    font-weight: 600;
}

.upload-dropzone__text-small {
    margin-bottom: 20px;
    color: #868686;
    font-size: 12px;
    font-weight: 600;
}

.upload-dropzone__thumbs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    align-items: stretch;
    z-index: 100;
    position: relative;
}

.upload-dropzone__thumb {
    width: 100px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid #CBCBCB;
    background-color: white;
    z-index: 100;
}

.upload-dropzone__camera {
    width: 19px;
    height: 19px;
    color: #146ca7;
}

.upload-dropzone__thumb img {
    width: 100%;
    display: block;
}

.upload-dropzone__thumb-close {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 1px 5px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    cursor: pointer;
    z-index: 100;
}

.upload-dropzone__button-wrapper {
    display: flex;
    gap: 5px;
}

.upload-dropzone__thumb-enter {
    opacity: 0;
}

.upload-dropzone__thumb-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}

.upload-dropzone__thumb-exit {
    opacity: 1;
}

.upload-dropzone__thumb-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

@media (max-width: 768px) {
    .upload-dropzone__body {
        width: 100%;
    }

    .create-wave-page__form .upload-dropzone__thumbs {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 900px) {
    .upload-dropzone__body {
        width: 100%;
    }

    .profile-page__ndfl-form .upload-dropzone__thumbs {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 900px) and (max-width: 1200px) {
    .upload-dropzone__body {
        width: 100%;
    }

    .profile-page__ndfl-form .upload-dropzone__thumbs {
        grid-template-columns: repeat(3, 1fr);
    }
}