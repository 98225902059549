.profile-page {
    /*padding-top: 140px;*/
}

.profile-page__title {
    margin-bottom: 30px;
}

.profile-page__alert {
    margin-bottom: 20px;
}

.profile-page__forms {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.profile-page__contact-form {
    width: 45%;
    /*padding-right: 50px;*/
}

.profile-page__ndfl-form {
    width: 50%;
    padding-left: 50px;
}

.profile-page__delivery-address-form {
    width: 50%;
    padding-left: 50px;
}


.profile-page__point-form {
    width: 50%;
}
.profile-page__sent {
    padding: 15px 25px;
    font-weight: bold;
    font-size: 18px;
}

@media (max-width: 700px) {
    .profile-page__forms {
        display: flex;
        flex-direction: column;
    }
    .profile-page__contact-form {
        width: 100%;
        padding-right: 0;
    }
    .profile-page__ndfl-form, .profile-page__delivery-address-form {
        width: 100%;
        padding-left: 0;
    }

    .profile-page__ndfl-form .button {
        width: 100%;
    }
    .profile-page__title {
        margin-bottom: 10px;
        margin-bottom: 10px;
    }

}